import { api } from './api';
import { CircleCadVersion } from '../models/circle-cad-version';

export const circleCadVersionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCircleCadVersions: builder.query<CircleCadVersion[], void>({
      query: () => {
        return `/manufacturing/circle-cad-versions`;
      }
    })
  })
});

export const { useGetCircleCadVersionsQuery } = circleCadVersionApi;
