import { ReactNode } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import {
  Chips,
  DropdownMenu,
  IconButton,
  Link,
  Text,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import {
  FilterProps,
  MasterDetailsProps,
  MenuEntry,
  TypeColWithNamePropertyPlatform
} from '../../../../models/datagrid';
import datagridStyles from '../../../../features/datagrid/datagrid-feature.module.scss';
import { Product, ProductLabActivation } from '../../../../models/product';
import styles from '../products-page/products-page.module.scss';
import { CommonTypes } from '../../../../models/common-types';
import { getLocalizedProperty } from '../catalog';
import { TypeRowDetailsInfo } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import {
  computeMasterDetailsHeight,
  getColumnOptions
} from '../../../../features/datagrid/datagrid.utils.ts';

const buildMenu = (
  product: Product,
  activateLabCallback: (product: Product) => void,
  isConnectedUserAdmin: boolean
): MenuEntry[][] => {
  return [
    [
      {
        label: i18next.t('action.detail', { ns: 'common' }),
        type: 'nav',
        link: `/catalog/products/${product.id}/detail`
      },
      {
        label: i18next.t('products.datagrid.actions.activateLab', { ns: 'catalog' }),
        onClick: () => {
          activateLabCallback(product);
        },
        type: 'button',
        disabled: !isConnectedUserAdmin
      }
    ],
    [
      {
        label: i18next.t('action.deactivate', { ns: 'common' }),
        onClick: () => {
          console.log('coming soon');
        },
        type: 'button',
        disabled: true
      }
    ]
  ];
};

export const getFilters = (filterValue: Array<FilterProps> | undefined) => {
  let filters = '';
  filterValue
    ?.filter((filter) => filter.value)
    ?.forEach((filter) => {
      switch (filter.name) {
        case 'name':
        case 'circleCadVersion':
          filters = `${filters}&filter.${getLocalizedProperty(filter.name)}=$ilike:${filter.value}`;
          break;
        case 'family':
        case 'category': {
          const multipleSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.${filter.name}=$in:${multipleSelectValues.join(',')}`;
          break;
        }
        case 'deletedAt':
          filters = `${filters}&filter.deletedAt=${
            filter.value === 'active' ? '$null' : '$not:$null'
          }`;
          break;
        default:
          filters = `${filters}&filter.${filter.name}=$ilike:${filter.value}`;
          break;
      }
    });

  return filters;
};

export const masterDetailsHeight = ({ data }: MasterDetailsProps) => {
  return computeMasterDetailsHeight(20, 5, 25, (data as Product).components || []);
};

export const renderMasterDetails = ({ data }: TypeRowDetailsInfo): ReactNode => {
  const componentsInProducts = (data as Product).components || [];

  const details = componentsInProducts.map((thisComponent) => {
    type ObjectKey = keyof typeof thisComponent;
    const labelPropertyName = getLocalizedProperty('name') as ObjectKey;
    const idPropertyName = 'id' as ObjectKey;
    return (
      <li
        className={styles['products-page__datagrid__details__row']}
        key={thisComponent.componentType}>
        <span className={styles['products-page__datagrid__details__row__title']}>
          {i18next.t(`componentTypes.${thisComponent.componentType.toUpperCase()}`, {
            ns: 'catalog'
          })}
        </span>
        <Link
          href={`/catalog/components/${thisComponent[idPropertyName]}/detail?origin=products`}
          label={thisComponent[labelPropertyName]}
        />
      </li>
    );
  });

  return componentsInProducts ? (
    <ul className={styles['products-page__datagrid__details']}>{details}</ul>
  ) : (
    '-'
  );
};

export const getColumnSettings = (
  commonTypes: CommonTypes,
  activateLabCallback: (product: Product) => void,
  isConnectedUserAdmin: boolean
): TypeColWithNamePropertyPlatform[] => [
  {
    ...getColumnOptions(
      'deletedAt',
      i18next.t('products.datagrid.headers.status', { ns: 'catalog' }),
      1,
      false
    ),
    maxWidth: 125,
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: [
        { id: 'active', label: i18next.t('active', { ns: 'common' }) },
        { id: 'inactive', label: i18next.t('inactive', { ns: 'common' }) }
      ]
    },
    render({ value }) {
      const chip = (
        <Chips
          firstLabel={i18next.t(value ? 'inactive' : 'active', { ns: 'common' })}
          color={value ? ColorPropsEnum.WARNING : ColorPropsEnum.SUCCESS}
        />
      );
      return value ? (
        <Tooltip>
          <TooltipContent>
            {moment(value).format(i18next.t('date.full', { ns: 'common' }))}
          </TooltipContent>
          {chip}
        </Tooltip>
      ) : (
        chip
      );
    }
  },
  {
    ...getColumnOptions('name', i18next.t('products.datagrid.headers.name', { ns: 'catalog' }), 2),
    render({ data }) {
      const localizedLabel = getLocalizedProperty('name');
      return (
        <Link href={`/catalog/products/${data.id}/detail`} label={data[localizedLabel]}></Link>
      );
    }
  },
  {
    ...getColumnOptions('family', i18next.t('products.datagrid.headers.family', { ns: 'catalog' })),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource:
        commonTypes.families &&
        Object.values(commonTypes.families).map((family) => {
          return {
            id: family,
            label: i18next.t(`products.families.${family}`, {
              ns: 'catalog'
            })
          };
        })
    },
    sortable: false,
    render({ value, data }) {
      return (
        <Chips
          firstLabel={i18next.t(`products.families.${value}`, { ns: 'catalog' })}
          color={ColorPropsEnum[`FAMILY_${value.toUpperCase()}` as keyof typeof ColorPropsEnum]}
          secondLabel={
            data.class
              ? i18next.t(`products.classes.${data.class}`, {
                  ns: 'catalog'
                })
              : ''
          }></Chips>
      );
    }
  },
  {
    ...getColumnOptions(
      'category',
      i18next.t('products.datagrid.headers.category', { ns: 'catalog' })
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource:
        commonTypes.productCategories &&
        Object.values(commonTypes.productCategories).map((category) => {
          return {
            id: category,
            label: i18next.t(`products.categories.${category}`, {
              ns: 'catalog'
            })
          };
        })
    },
    sortable: false,
    render({ value, data }: { value: string; data: Product }) {
      const getCategoryDisplay = (color: ColorPropsEnum.WHITE | ColorPropsEnum.GREY) => {
        return (
          <Text
            data-cy="category-display"
            color={color}
            label={value ? i18next.t(`products.categories.${value}`, { ns: 'catalog' }) : '-'}
          />
        );
      };
      let subCategories;
      if (data.subCategories?.length) {
        subCategories = (
          <div data-cy="subCategories-display">
            {data.subCategories.map((subCategory) => (
              <p key={subCategory} className={styles['products-page__datagrid__sub-text']}>
                {i18next.t(`products.subCategoriesShort.${subCategory}`, { ns: 'catalog' })}
              </p>
            ))}
          </div>
        );
      }

      if (!subCategories) {
        return (
          <div
            className={styles['products-page__datagrid__row']}
            data-cy="datagrid__product__category">
            {getCategoryDisplay(ColorPropsEnum.GREY)}
          </div>
        );
      }

      return (
        <Tooltip>
          <TooltipContent data-cy="datagrid__product__category__tooltip">
            {getCategoryDisplay(ColorPropsEnum.WHITE)}
            {subCategories}
          </TooltipContent>
          <div
            className={styles['products-page__datagrid__row']}
            data-cy="datagrid__product__category">
            {getCategoryDisplay(ColorPropsEnum.GREY)}
            {value && subCategories && (
              <Text
                data-cy="subcategories-count"
                label={i18next.t('products.datagrid.content.subCategories', {
                  ns: 'catalog',
                  count: data?.subCategories?.length
                })}
                color={ColorPropsEnum.GREY}
              />
            )}
          </div>
        </Tooltip>
      );
    }
  },
  {
    ...getColumnOptions(
      'components',
      i18next.t('products.datagrid.headers.components', { ns: 'catalog' }),
      undefined,
      false
    ),
    render({ data }) {
      const componentsInProducts = (data as Product).components || [];

      if (componentsInProducts.length) {
        return (
          <p>
            {i18next.t('products.datagrid.content.components', {
              ns: 'catalog',
              count: componentsInProducts.length
            })}
          </p>
        );
      } else {
        return '-';
      }
    }
  },
  getColumnOptions(
    'circleCadVersion',
    i18next.t('products.datagrid.headers.circle_cad_version', { ns: 'catalog' })
  ),
  {
    ...getColumnOptions(
      'labsActivated',
      i18next.t('products.datagrid.headers.labs', { ns: 'catalog' }),
      undefined,
      false
    ),
    render({ data }) {
      if (data.labProducts?.length) {
        return (
          <Tooltip>
            <TooltipContent>
              {data.labProducts.map((lab: ProductLabActivation) => lab.labName).join(', ')}
            </TooltipContent>
            {
              <>
                {i18next.t(
                  'products.datagrid.headers.labs' +
                    (data.labProducts.length > 1 ? '_many' : '_one'),
                  {
                    ns: 'catalog',
                    count: data.labProducts.length
                  }
                )}
              </>
            }
          </Tooltip>
        );
      } else {
        return '-';
      }
    }
  },
  {
    ...getColumnOptions('menu', '', 1, false),
    maxWidth: 50,
    showColumnMenuTool: false,
    render: ({ data }) => {
      return (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton faIconClass="ellipsis-vertical" isActive={active} radius="full" />
          )}
          data={buildMenu(data, activateLabCallback, isConnectedUserAdmin)}
        />
      );
    }
  }
];

export const rowClassName = ({ data }: { data: Product }): string | undefined => {
  if (data.deletedAt) {
    return datagridStyles['datagrid-feature__row--disabled'];
  }
  return undefined;
};
