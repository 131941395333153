import ListItem from './list-item/ListItem';
import styles from './list-feature.module.scss';
import { ListItemProps } from './list-item/list-item.utils.ts';

export type ListFeatureProps = {
  items: ListItemProps[];
  className?: string;
};

const ListFeature = ({ items, className }: ListFeatureProps) => {
  const classNames = className
    ? [styles['list-feature'], className].join(' ')
    : styles['list-feature'];

  return (
    <ul className={classNames}>
      {items.map((item) => {
        return (
          <ListItem
            key={`item__${item.itemKey}`}
            itemKey={item.itemKey}
            headlineHref={item.headlineHref}
            headline={item.headline}
            supportingText={item.supportingText}
            actions={item.actions}
            bgColor={item.bgColor}
          />
        );
      })}
    </ul>
  );
};

export default ListFeature;
