import { useCallback, useState } from 'react';
import styles from './orders-page.module.scss';
import { Button, SideBarModal, Tabs } from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { TabsType } from '../../../models/navigation';
import { useTranslation } from 'react-i18next';
import { useLazyGetOrdersQuery } from '../../../services/orders.services';
import DatagridFeature from '../../../features/datagrid/DatagridFeature';
import { LoadDataProps, ServicePaginatedResponse } from '../../../models/datagrid';
import { getColumnSettings, getFilters, getSort, rowClassname } from './orders';
import { datagridSettingsActions } from '../../../store/datagrids-settings/datagrids-settings.reducers';
import { ordersDatagridStateSelector } from '../../../store/datagrids-settings/datagrids-settings.selectors';

import {
  TypeSingleSortInfo,
  TypeSortInfo
} from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import ExportManufacturedOrdersForm from './export-orders-form/ExportManufacturedOrdersForm';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import { useGetCommonTypesQuery } from '../../../services/common-types-rtkq.services';

const OrdersPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'orders']);
  const datagridSettings = useAppSelector(ordersDatagridStateSelector);
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const [sideBarExportOpened, setSideBarExportOpened] = useState(false);
  const [getOrders] = useLazyGetOrdersQuery();
  const loadData = ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = getFilters(filterValue);
    const sort = getSort(sortInfo);

    return getOrders({ page, limit, filters, sort })
      .unwrap()
      .then((orders: ServicePaginatedResponse) => {
        return { data: orders.data, count: orders.meta.totalItems };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };

  const dataSource = useCallback(loadData, []);

  const onFilterValueChange = (newFilterValue: TypeFilterValue) => {
    dispatch(datagridSettingsActions.setOrdersDatagridFilters(newFilterValue));
  };

  const onSortInfoChange = (newSortInfo: TypeSortInfo) => {
    const newSortInfoObject = newSortInfo as TypeSingleSortInfo;
    dispatch(
      datagridSettingsActions.setOrdersDatagridSort({
        name: newSortInfoObject?.name,
        dir: newSortInfoObject?.dir
      })
    );
  };

  const removeFilters = () => {
    dispatch(datagridSettingsActions.resetOrdersDatagridFilters());
  };

  const tabs: TabsType[] = [
    {
      label: t('tabs.orders', { ns: 'orders' }),
      disabled: false,
      to: '/orders'
    }
  ];

  return (
    <>
      <div className={styles['orders-page__tab']}>
        <Tabs tabs={tabs} id={'dashboard'}></Tabs>
      </div>
      <div className={styles['orders-page__actions']} id={'actions-buttons'}>
        <Button
          category="tertiary"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={removeFilters}
        />
        <Button
          data-cy="exportManufacturedOrders"
          className={styles['orders-page__actions__export']}
          category="secondary"
          iconLeft="fa-file-excel"
          id={'export-manufactured-orders'}
          label={t('datagrid.export.title', { ns: 'orders' })}
          onClick={() => setSideBarExportOpened(true)}
        />
      </div>
      <div className={styles['orders-page__content']}>
        {!areCommonTypesLoading && (
          <DatagridFeature
            key="orders"
            style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
            columns={getColumnSettings(commonTypes)}
            filterValue={datagridSettings.filters}
            onFilterValueChange={onFilterValueChange}
            sortInfo={datagridSettings.sort}
            onSortInfoChange={onSortInfoChange}
            dataSource={dataSource}
            rowClassName={rowClassname}
          />
        )}
      </div>
      {sideBarExportOpened && (
        <SideBarModal
          title={t('datagrid.export.title', {
            ns: 'orders'
          })}
          isOpened={sideBarExportOpened}
          closeOnOutsideClick={true}
          onClose={() => setSideBarExportOpened(false)}>
          <ExportManufacturedOrdersForm
            onSubmitCallback={(keepOpened: boolean) =>
              setSideBarExportOpened(keepOpened)
            }></ExportManufacturedOrdersForm>
        </SideBarModal>
      )}
    </>
  );
};

export default OrdersPage;
