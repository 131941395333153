import { Box, Button, RadioList } from '@anatoscope/circlestorybook';
import i18next from 'i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { userSelector } from '../../../../../store/users/users.selectors';
import { usersActions } from '../../../../../store/users/users.reducers';
import { DentalNotation, Language } from '../../../../../enum/user';
import { UserInfo } from '../../../../../models/user';
import styles from './user-forms.module.scss';
import { dentalNotations, languages } from '../../../../../utils/utils';
import useForm from '../../../../../utils/useForm';
import { ColorPropsEnum } from '../../../../../enum/color.enum';

type Props = {
  saveCallback: (user: Partial<UserInfo>, email: string) => void;
  closeSideNavCallback: (opened: boolean) => void;
};

const CustomizationForm = ({ saveCallback, closeSideNavCallback }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);

  const saveCustomizationInfo = () => {
    dispatch(
      usersActions.setUser({
        ...user,
        ...values
      })
    );
    if (user?.email) {
      saveCallback(
        {
          ...values
        },
        user?.email
      );
    }
    closeSideNavCallback(false);
  };

  const onSubmit = () => {
    saveCustomizationInfo();
  };

  const { values, handleSubmit, handleSelect } = useForm(
    {
      dentalNotation: user?.dentalNotation ? user.dentalNotation : DentalNotation.ISO,
      language: user?.language ? user.language : Language.fr_FR
    },
    onSubmit
  );

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form onSubmit={handleSubmit}>
        <RadioList
          title={i18next.t('userForm.dentalNotation', { ns: 'user' })}
          options={dentalNotations}
          name="dentalNotation"
          selectedValue={values.dentalNotation}
          onClick={(newValue: DentalNotation) => handleSelect(newValue, 'dentalNotation')}
          className={styles['user-forms__input']}
        />
        <RadioList
          title={i18next.t('userForm.language', { ns: 'user' })}
          options={languages}
          name="language"
          selectedValue={values.language}
          onClick={(newValue: Language) => handleSelect(newValue, 'language')}
        />
        <div className="form__submit-button">
          <Button label={i18next.t('action.update', { ns: 'common' })} type="submit" />
        </div>
      </form>
    </Box>
  );
};
export default CustomizationForm;
