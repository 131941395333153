import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { getUserByEmail, patchUser } from '../../services/users.services';
import { UserInfo, UsersReducer } from '../../models/user';
import { capitalize } from '../../utils/utils';

const initialUsers: UsersReducer = {
  user: undefined,
  isUserLoading: true,
  tags: {},
  isLoadingTags: false,
  isLoadingCreationUser: false,
  isLoadingPatchUser: false
};

const fetchUserByEmailAsync = createAsyncThunk(
  'users/loadUserByEmail',
  async (email: string, thunkAPI) => {
    try {
      const result = await getUserByEmail(email);
      return result && mapUser(result.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const patchUserAsync = createAsyncThunk(
  'users/patch',
  async (user: Partial<UserInfo>, thunkAPI) => {
    const { email, ...fields } = user;
    try {
      if (email) {
        await patchUser(fields, email);
      } else {
        throw new Error('Email is required');
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsers,
  reducers: {
    setUser: (state, action: PayloadAction<UserInfo | undefined>) => {
      state.user = action.payload;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<UsersReducer>) => {
    builder
      .addCase(fetchUserByEmailAsync.pending, (state) => {
        state.isUserLoading = true;
      })
      .addCase(fetchUserByEmailAsync.fulfilled, (state, actions) => {
        state.isUserLoading = false;
        state.user = actions.payload;
      })
      .addCase(fetchUserByEmailAsync.rejected, (state) => {
        state.isUserLoading = false;
      })
      .addCase(patchUserAsync.pending, (state) => {
        state.isLoadingPatchUser = true;
      })
      .addCase(patchUserAsync.fulfilled, (state) => {
        state.isLoadingPatchUser = false;
      })
      .addCase(patchUserAsync.rejected, (state) => {
        state.isLoadingPatchUser = false;
      })
      .addCase('RESET_ALL', () => {
        return { ...initialUsers };
      });
  }
});

const mapUser = (user: UserInfo): UserInfo => {
  if (user?.firstName && user?.lastName) {
    return {
      ...user,
      firstName: capitalize(user.firstName),
      lastName: capitalize(user.lastName)
    };
  } else {
    return user;
  }
};

const usersActions = usersSlice.actions;

export { fetchUserByEmailAsync, usersSlice, usersActions, patchUserAsync };
