import styles from '../../create-user-page.module.scss';
import { Button, Dropdown, Fieldset, TextField } from '@anatoscope/circlestorybook';
import i18next from 'i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  isConnectedUserAdminSelector,
  userSelector
} from '../../../../../../store/users/users.selectors';
import { usersActions } from '../../../../../../store/users/users.reducers';
import { AdminRole, DentistRole, DesignerRole, LabUserRole } from '../../../../../../enum/user';
import useForm from '../../../../../../utils/useForm';
import { StringObject } from '../../../../../../models/common';
import { isDentistRole } from '../../../../../../utils/utils';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { getAvailableRolesForCurrentUser } from './identification-form.utils.ts';

type Props = {
  nextCallback: () => void;
};

const IdentificationForm = ({ nextCallback }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const roleMandatory = i18next.t('userForm.roleMandatory', { ns: 'user' });
  const emailMandatory = i18next.t('userForm.emailMandatory', { ns: 'user' });
  const firstNameMandatory = i18next.t('userForm.firstNameMandatory', { ns: 'user' });
  const lastNameMandatory = i18next.t('userForm.lastNameMandatory', { ns: 'user' });
  const phoneMandatory = i18next.t('userForm.phoneMandatory', { ns: 'user' });
  const isConnectedUserAdmin = useAppSelector(isConnectedUserAdminSelector);

  const isDentistOrAssistantRole = () => isDentistRole(values.role as DentistRole);

  const onSubmit = () => {
    dispatch(
      usersActions.setUser({
        ...user,
        ...values,
        phoneNumber:
          isDentistOrAssistantRole() && values.phoneNumber
            ? (values.phoneNumber as string)
            : undefined
      })
    );

    nextCallback();
  };

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      if (
        (!values[key] && key !== 'phoneNumber') ||
        (key === 'phoneNumber' && isDentistOrAssistantRole() && !values[key])
      ) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const { values, errors, handleSubmit, handleSelect, handleBlur, handleChange } = useForm(
    {
      role: user?.role as LabUserRole | AdminRole | DentistRole | DesignerRole,
      email: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNumber: user?.phoneNumber || ''
    },
    onSubmit,
    validateAllFieldsRequired
  );

  return (
    <form onSubmit={handleSubmit} className={styles['create-user-form__form']}>
      <Fieldset size="m" className={styles['create-user-form__form__fieldset']}>
        <>
          <Dropdown
            label={i18next.t('userForm.role', { ns: 'user' })}
            data={getAvailableRolesForCurrentUser(isConnectedUserAdmin)}
            value={values.role}
            placeholder={i18next.t('userForm.choose', { ns: 'user' })}
            onChange={(newValue: DentistRole | LabUserRole | AdminRole | DesignerRole) =>
              handleSelect(newValue, 'role')
            }
            helperText={errors.role ? roleMandatory : undefined}
            variant={errors.role ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-user-form__form__input']}
          />
          <TextField
            label={i18next.t('userForm.email', { ns: 'user' })}
            id={'email'}
            name="email"
            value={values.email}
            type={'email'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={errors.email ? emailMandatory : undefined}
            variant={errors.email ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-user-form__form__input']}
            placeholder="email@circle.com"
          />
          <TextField
            id={'firstName'}
            name="firstName"
            label={i18next.t('userForm.firstName', { ns: 'user' })}
            value={values.firstName}
            type={'text'}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={errors.firstName ? firstNameMandatory : undefined}
            variant={errors.firstName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-user-form__form__input']}
            placeholder="John"
          />
          <TextField
            id={'lastName'}
            name="lastName"
            label={i18next.t('userForm.lastName', { ns: 'user' })}
            value={values.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            type={'text'}
            helperText={errors.lastName ? lastNameMandatory : undefined}
            variant={errors.lastName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-user-form__form__input']}
            placeholder="Smith"
          />
          {isDentistOrAssistantRole() && (
            <TextField
              label={i18next.t('userForm.phone', { ns: 'user' })}
              id={'phone'}
              name="phoneNumber"
              value={values.phoneNumber}
              type={'tel'}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={
                errors.phoneNumber
                  ? phoneMandatory
                  : i18next.t('userForm.helperText.phone', { ns: 'user' })
              }
              variant={errors.phoneNumber ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
              placeholder={i18next.t('userForm.placeholder.phone', { ns: 'user' })}
            />
          )}
        </>
      </Fieldset>
      <div className="form__submit-button">
        <Button
          label={i18next.t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};
export default IdentificationForm;
