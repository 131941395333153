import UserDetails from '../../../features/user-details/UserDetails';
import { useAppSelector } from '../../../store/hooks';
import { userInfoSelector } from '../../../store/auth/auth.selectors';

const ProfilePage = () => {
  const connectedUser = useAppSelector(userInfoSelector);

  return connectedUser?.email && <UserDetails isReadOnly={false} email={connectedUser.email} />;
};

export default ProfilePage;
