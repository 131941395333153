export enum ItemTypeBgColorEnum {
  WHITE = 'white',
  GREY = 'grey'
}

export type ListItemProps = {
  itemKey: string;
  headlineHref?: string;
  headline: string;
  supportingText?: string;
  bgColor?: ItemTypeBgColorEnum;
  actions?: {
    faIconClass: string;
    color?: string;
    onClick: (id?: number) => void;
    className?: string;
    helper?: string;
    dataCy?: string;
  }[];
};
