import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { Establishment, EstablishmentsReducer } from '../../models/establishment';
import {
  getClinics,
  getDesignCenters,
  getLaboratories
} from '../../services/establishments.services';
import { TEMP_NEW_ESTABLISHMENT_ID } from '../../enum/establishment';

const initialClinics: EstablishmentsReducer = {
  isClinicsLoading: false,
  isDesignCentersLoading: false,
  isLaboratoriesLoading: false,
  clinicList: [],
  laboratoryList: [],
  designCenterList: []
};

export const fetchClinicsAsync = createAsyncThunk('users/loadClinics', async (_arg, thunkAPI) => {
  try {
    const result = await getClinics();
    return result.data.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchLaboratoriesAsync = createAsyncThunk(
  'users/loadLaboratories',
  async (_arg, thunkAPI) => {
    try {
      const result = await getLaboratories();
      return result.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchDesignCentersAsync = createAsyncThunk(
  'users/loadDesignCenters',
  async (_arg, thunkAPI) => {
    try {
      const result = await getDesignCenters();
      return result.data.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const establishmentsSlice = createSlice({
  name: 'establishments',
  initialState: initialClinics,
  reducers: {
    addClinicToList: (state, action: PayloadAction<Establishment>) => {
      state.clinicList = [...state.clinicList, action.payload];
    },
    removeNewClinicInList: (state) => {
      state.clinicList = state.clinicList.filter(
        (clinic) => clinic.id !== TEMP_NEW_ESTABLISHMENT_ID
      );
    },
    addDesignCenterToList: (state, action: PayloadAction<Establishment>) => {
      state.designCenterList = [...state.designCenterList, action.payload];
    },
    removeNewDesignCenterInList: (state) => {
      state.designCenterList = state.designCenterList.filter(
        (designCenter) => designCenter.id !== TEMP_NEW_ESTABLISHMENT_ID
      );
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<EstablishmentsReducer>) => {
    builder
      .addCase(fetchClinicsAsync.pending, (state) => {
        state.isClinicsLoading = true;
      })
      .addCase(fetchClinicsAsync.fulfilled, (state, actions) => {
        state.isClinicsLoading = false;
        state.clinicList = actions.payload;
      })
      .addCase(fetchClinicsAsync.rejected, (state) => {
        state.isClinicsLoading = false;
      })

      .addCase(fetchLaboratoriesAsync.pending, (state) => {
        state.isLaboratoriesLoading = true;
      })
      .addCase(fetchLaboratoriesAsync.fulfilled, (state, actions) => {
        state.isLaboratoriesLoading = false;
        state.laboratoryList = actions.payload;
      })
      .addCase(fetchLaboratoriesAsync.rejected, (state) => {
        state.isLaboratoriesLoading = false;
      })

      .addCase(fetchDesignCentersAsync.pending, (state) => {
        state.isDesignCentersLoading = true;
      })
      .addCase(fetchDesignCentersAsync.fulfilled, (state, actions) => {
        state.isDesignCentersLoading = false;
        state.designCenterList = actions.payload;
      })
      .addCase(fetchDesignCentersAsync.rejected, (state) => {
        state.isDesignCentersLoading = false;
      })
      .addCase('RESET_ALL', () => {
        return { ...initialClinics };
      });
  }
});

const establishmentActions = establishmentsSlice.actions;

export { establishmentsSlice, establishmentActions };
