import axios, { AxiosResponse } from 'axios';
import { Component, ComponentDetails, NewComponent } from '../models/component';

export const getPaginatedComponents = (
  page: number,
  limit: number,
  filters: string,
  sort: string
): Promise<AxiosResponse> => {
  return axios.get(`/components?page=${page}&limit=${limit}${filters}${sort}`);
};

export const getComponentById = (id: string): Promise<AxiosResponse> => {
  return axios.get(`/components/${id}`);
};

export const deactivateComponent = (
  component: Component | ComponentDetails
): Promise<AxiosResponse> => {
  const url = `/components/${component.id}`;
  return axios.delete(url);
};

export const createComponent = (component: NewComponent): Promise<AxiosResponse> => {
  const url = `/components`;
  return axios.post(url, component);
};
