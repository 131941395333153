import { api } from './api';
import { getLocalizedProperty } from '../pages/private/catalog-page/catalog';
import { Component } from '../models/component';
import {
  ActiveComponentsDropdownItems,
  AddComponentFormData
} from '../pages/private/catalog-page/products-page/create-product-page/create-product-forms/add-component-form/add-component';
import { ComponentType } from '../enum/component-type';
import { DropdownItem } from '../models/form';

export const componentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActiveComponents: builder.query<
      ActiveComponentsDropdownItems,
      { filters?: string; sort?: string; responseFormat?: string }
    >({
      query: ({ filters, sort }) => {
        if (!sort) sort = `&sortBy=${getLocalizedProperty('label')}:ASC`;
        // this has to stay non paginated (limit=0)
        return `/components?page=1&limit=0&filter.deletedAt=$null${filters || ''}${sort}`;
      },
      transformResponse: (response: { data: Component[] }) => {
        return {
          FRAME: response.data
            .filter((component) => component.componentType === ComponentType.FRAME)
            ?.map((component) => {
              return {
                value: component.id,
                label: component[getLocalizedProperty('label') as keyof Component] as string
              } as DropdownItem;
            }),
          TOOTH: filterComponentsByType(response.data, ComponentType.TOOTH),
          GINGIVA: filterComponentsByType(response.data, ComponentType.GINGIVA),
          INFRASTRUCTURE: filterComponentsByType(response.data, ComponentType.INFRASTRUCTURE),
          PROSTHETIC_STUMP: filterComponentsByType(response.data, ComponentType.PROSTHETIC_STUMP),
          IMPLANT_ATTACHMENT: filterComponentsByType(
            response.data,
            ComponentType.IMPLANT_ATTACHMENT
          ),
          WAX_ON_HARD_BASE: filterComponentsByType(response.data, ComponentType.WAX_ON_HARD_BASE),
          IMPLANT_SYSTEM: filterComponentsByType(response.data, ComponentType.IMPLANT_SYSTEM),
          GUARD: filterComponentsByType(response.data, ComponentType.GUARD),
          PARTIAL_TOOTH: filterComponentsByType(response.data, ComponentType.PARTIAL_TOOTH),
          MODELS: filterComponentsByType(response.data, ComponentType.MODELS),
          PINK_GINGIVA: filterComponentsByType(response.data, ComponentType.PINK_GINGIVA)
        } as ActiveComponentsDropdownItems;
      },
      providesTags: ['Components']
    }),
    getOneComponent: builder.query<Component, string | undefined>({
      query: (id) => {
        return `/components/${id}`;
      },
      providesTags: ['OneComponent']
    }),
    patchComponent: builder.mutation<Component, AddComponentFormData>({
      query: ({ componentId, ...patch }) => ({
        url: `/components/${componentId}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['Components', 'OneComponent']
    }),
    deleteComponent: builder.mutation<void, { componentId: string }>({
      query: ({ componentId }) => ({
        url: `/components/${componentId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Components', 'OneComponent']
    })
  })
});

const filterComponentsByType = (
  components: Component[],
  componentType: ComponentType
): DropdownItem[] => {
  type ComponentWithId = Component & { id: number };
  const componentsWithId: ComponentWithId[] = components.filter(
    (thisComponent: Component) =>
      thisComponent.componentType === componentType && thisComponent.id !== undefined
  ) as ComponentWithId[];

  return componentsWithId.map((thisComponent: ComponentWithId): DropdownItem => {
    return {
      value: thisComponent.id,
      label: thisComponent[getLocalizedProperty('label') as keyof Component] as string
    };
  });
};

export const {
  useGetOneComponentQuery,
  useGetActiveComponentsQuery,
  useLazyGetActiveComponentsQuery,
  usePatchComponentMutation,
  useDeleteComponentMutation
} = componentsApi;
