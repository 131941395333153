import { Establishment, EstablishmentsReducer } from '../../models/establishment';
import { createSelector } from '@reduxjs/toolkit';
import { DropdownItem } from '../../models/form';

interface ReducerState {
  establishmentsState: EstablishmentsReducer;
}

export const clinicListSelector = createSelector(
  (state: ReducerState) => state.establishmentsState,
  (establishments: EstablishmentsReducer) =>
    establishments?.clinicList ? establishments.clinicList : []
);

export const clinicListForDropdownSelector = createSelector(
  (state: ReducerState) => state.establishmentsState,
  (establishments: EstablishmentsReducer) => mapForDropdown(establishments?.clinicList)
);

export const laboratoryListSelector = createSelector(
  (state: ReducerState) => state.establishmentsState,
  (establishments: EstablishmentsReducer) =>
    establishments?.laboratoryList ? establishments.laboratoryList : []
);

export const laboratoryListForDropdownSelector = createSelector(
  (state: ReducerState) => state.establishmentsState,
  (establishments: EstablishmentsReducer) => mapForDropdown(establishments?.laboratoryList)
);

export const designCenterListSelector = createSelector(
  (state: ReducerState) => state.establishmentsState,
  (establishments: EstablishmentsReducer) =>
    establishments?.designCenterList ? establishments.designCenterList : []
);

export const designCenterListForDropdownSelector = createSelector(
  (state: ReducerState) => state.establishmentsState,
  (establishments: EstablishmentsReducer) => mapForDropdown(establishments?.designCenterList)
);

export const isLaboratoriesLoadingSelector = (state: ReducerState) =>
  state.establishmentsState.isLaboratoriesLoading;

export const isDesignCentersLoadingSelector = (state: ReducerState) =>
  state.establishmentsState.isDesignCentersLoading;

export const isClinicsLoadingSelector = (state: ReducerState) =>
  state.establishmentsState.isClinicsLoading;

const mapForDropdown = (establishments: Establishment[]): DropdownItem[] => {
  if (establishments && establishments.length > 0) {
    return establishments
      .filter(
        (ets): ets is Establishment & { name: string; id: number } =>
          Boolean(ets?.name) && Boolean(ets?.id)
      )
      .map((ets) => {
        return { label: ets.name, value: ets.id };
      });
  }
  return [];
};
