import { api } from './api';
import { UserInfo } from '../models/user';
import { mapUser } from '../pages/private/users-page/users.tsx';

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation<UserInfo, string>({
      query: (email) => ({
        url: `/users/${email}/reset-password`,
        method: 'POST'
      })
    }),
    createUser: builder.mutation<UserInfo, UserInfo>({
      query: (user) => ({
        url: '/users',
        method: 'POST',
        body: user
      })
    }),
    getConnectedUser: builder.query<UserInfo, void>({
      query: () => ({
        url: `/users/me`,
        method: 'GET'
      }),
      transformResponse: (response: UserInfo) => {
        return mapUser(response);
      },
      providesTags: ['ConnectedUser']
    })
  })
});

export const { useResetPasswordMutation, useCreateUserMutation, useLazyGetConnectedUserQuery } =
  usersApi;
