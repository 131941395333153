import { useEffect, useState } from 'react';
import { BubblePicto, Button, Dropdown, Fieldset, TextField } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import useForm from '../../../../../../utils/useForm';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { productSelector } from '../../../../../../store/products/products.selectors';
import styles from '../create-product-page.module.scss';
import {
  familyDropdownSelector,
  productCategoriesDropdownSelector,
  productClassesDropdownSelector,
  productSubCategoriesDropdownSelector
} from '../../../../../../store/common-types/common-types.selectors';
import { productsActions } from '../../../../../../store/products/products.reducers';
import { StringObject, UnknownObject } from '../../../../../../models/common';
import { CreateProductNavProps } from '../CreateProductPage';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { ProductCreationForm } from '../../../../../../models/product.tsx';
import { useGetCommonTypesQuery } from '../../../../../../services/common-types-rtkq.services.ts';

const IdentificationForm = ({ nextStepCallback }: CreateProductNavProps) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const product = useAppSelector(productSelector);
  const familiesDropdown = useAppSelector(familyDropdownSelector);
  const classesDropdown = useAppSelector(productClassesDropdownSelector);
  const categoriesDropdown = useAppSelector(productCategoriesDropdownSelector);
  const subCategoriesDropdown = useAppSelector(productSubCategoriesDropdownSelector);
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const [previewUrlTimeout, setPreviewUrlTimeout] = useState<NodeJS.Timeout>();
  const [previewUrl, setPreviewUrl] = useState<string>();
  const {
    values,
    errors,
    handleSubmit,
    handleSelect,
    handleMultiSelect,
    handleBlur,
    handleChange
  } = useForm(
    {
      labelFr: product?.labelFr || '',
      labelEn: product?.labelEn || '',
      family: product?.family,
      class: product?.class,
      category: product?.category || '',
      subCategories: product?.subCategories || [subCategoriesDropdown],
      imageUrl: product?.imageUrl || ''
    },
    () => {
      dispatch(productsActions.setProduct({ ...product, ...values } as ProductCreationForm));
      if (nextStepCallback) nextStepCallback();
    },
    (values: UnknownObject) => {
      // Empty class if family is no longer Removable
      if (values?.family !== commonTypes?.families?.REMOVABLE && values?.class) {
        values.class = undefined;
      }
      const newErrors: StringObject = {};
      ['labelFr', 'labelEn', 'family', 'imageUrl'].forEach((key) => {
        if (!values[key]) {
          newErrors[key] = 'empty';
        }
      });

      if (values?.family === commonTypes?.families?.REMOVABLE && !values.class) {
        newErrors['class'] = 'empty';
      }

      return newErrors;
    }
  );
  useEffect(() => {
    clearTimeout(previewUrlTimeout);
    setPreviewUrlTimeout(
      setTimeout(() => {
        setPreviewUrl(values.imageUrl as string);
      }, 500)
    );
  }, [values.imageUrl]);

  useEffect(() => {
    // We have to do this, otherwise, subCategories are empty if common types are not done loading
    values.subCategories = product?.subCategories || [subCategoriesDropdown];
  }, [areCommonTypesLoading]);

  return (
    <form onSubmit={handleSubmit} className={styles['create-product-page__form']}>
      <Fieldset size="m" className={styles['create-product-page__form__fieldset']}>
        <TextField
          id="labelFr"
          name="labelFr"
          value={values.labelFr}
          label={t('products.productForm.labelFr.label')}
          placeholder="Couronne provisoire"
          helperText={
            errors.labelFr
              ? t('products.productForm.labelFr.error')
              : t('products.productForm.labelFr.helper')
          }
          className={styles['create-product-page__form__fieldset__input']}
          onBlur={handleBlur}
          onChange={handleChange}
          variant={errors.labelFr ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}></TextField>
        <TextField
          id="labelEn"
          name="labelEn"
          value={values.labelEn}
          label={t('products.productForm.labelEn.label')}
          placeholder="Provisional crown"
          helperText={
            errors.labelEn
              ? t('products.productForm.labelEn.error')
              : t('products.productForm.labelEn.helper')
          }
          className={styles['create-product-page__form__fieldset__input']}
          onBlur={handleBlur}
          onChange={handleChange}
          variant={errors.labelEn ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}></TextField>
        <Dropdown
          value={values.family}
          label={t('products.productForm.family.label')}
          data={areCommonTypesLoading ? [] : [familiesDropdown]}
          isLoading={areCommonTypesLoading}
          placeholder={t('products.productForm.dropdownPlaceholder')}
          onChange={(newValue: string) => handleSelect(newValue, 'family')}
          className={styles['create-product-page__form__fieldset__input']}
          variant={errors.family ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          helperText={errors.family && t('products.productForm.family.error')}></Dropdown>
        <Dropdown
          value={values.category}
          onChange={(newValue: string) => handleSelect(newValue, 'category')}
          label={t('products.productForm.category.label')}
          data={areCommonTypesLoading ? [] : [categoriesDropdown]}
          isLoading={areCommonTypesLoading}
          placeholder={t('products.productForm.dropdownPlaceholder')}
          className={styles['create-product-page__form__fieldset__input']}
          helperText={t('products.productForm.category.helper')}></Dropdown>
        <Dropdown
          multichoice={true}
          label={t('products.productForm.subCategories.label')}
          name="subCategories"
          onChange={(newValue: string) => handleMultiSelect(newValue, 'subCategories')}
          data={values.subCategories}
          isLoading={areCommonTypesLoading}
          placeholder={t('products.productForm.dropdownPlaceholder')}
          className={styles['create-product-page__form__fieldset__input']}
          helperText={t('products.productForm.subCategories.helper')}
          data-cy="product-form__sub-categories"></Dropdown>
        {!areCommonTypesLoading && values.family === commonTypes?.families?.REMOVABLE && (
          <Dropdown
            value={values.class}
            onChange={(newValue: string) => handleSelect(newValue, 'class')}
            label={t('products.productForm.class.label')}
            data={areCommonTypesLoading ? [] : [classesDropdown]}
            isLoading={areCommonTypesLoading}
            placeholder={t('products.productForm.dropdownPlaceholder')}
            variant={errors.class ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-product-page__form__fieldset__input']}
            helperText={errors.class && t('products.productForm.class.error')}></Dropdown>
        )}
        <div className={styles['create-product-page__form__fieldset__input__icon__container']}>
          <TextField
            id="imageUrl"
            name="imageUrl"
            value={values.imageUrl}
            label={t('products.productForm.icon.label')}
            placeholder={t('products.productForm.icon.placeholder')}
            helperText={
              errors.imageUrl
                ? t('products.productForm.icon.error')
                : t('products.productForm.icon.helper')
            }
            className={styles['create-product-page__form__fieldset__input__icon']}
            onBlur={handleBlur}
            onChange={handleChange}
            variant={errors.imageUrl ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}></TextField>
          {values.family && (
            <BubblePicto
              size="large"
              displaysBorder={true}
              data-cy="bubble-picto-preview"
              color={
                ColorPropsEnum[
                  `FAMILY_${(values.family as string).toUpperCase()}` as keyof typeof ColorPropsEnum
                ]
              }
              url={previewUrl}
            />
          )}
          {!values.family && (
            <BubblePicto size="large" data-cy="bubble-picto-preview" url={previewUrl} />
          )}
        </div>
      </Fieldset>
      <div className="form__submit-button form__submit-button--right">
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};

export default IdentificationForm;
