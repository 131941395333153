import { Tooltip, TooltipContent } from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';

const ForbiddenActionButton = ({ children }: { children: JSX.Element }) => {
  const { t } = useTranslation(['common']);

  return (
    <Tooltip>
      {children}
      <TooltipContent>{t('forbiddenActionButton.tooltip')}</TooltipContent>
    </Tooltip>
  );
};

export default ForbiddenActionButton;
