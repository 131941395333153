import {
  FilterProps,
  MenuEntry,
  SortDirection,
  SortReducer,
  TypeColWithNamePropertyPlatform
} from '../../../models/datagrid';
import i18next from 'i18next';
import {
  BubblePicto,
  Chips,
  DropdownMenu,
  IconButton,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import styles from '../../../features/datagrid/datagrid-feature.module.scss';
import { formatDate } from '../../../utils/utils';
import { CommonTypes } from '../../../models/common-types';
import { getLocalizedProperty } from '../catalog-page/catalog';
import { WorkflowModelingStepEnum } from '../../../enum/workflow-step';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Order } from '../../../models/order';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { Product } from '../../../models/product';
import { getColumnOptions } from '../../../features/datagrid/datagrid.utils.ts';

const getEditSectionMenu = (): MenuEntry[] => {
  return [
    {
      label: i18next.t('action.edit', { ns: 'common' }),
      disabled: true,
      type: 'button',
      onClick: () => {
        console.log('Test');
      }
    },
    {
      id: 'changeStep',
      label: i18next.t('changeStepModal.action', { ns: 'orders' }),
      type: 'button',
      onClick: () => {
        console.log('Test');
      }
    }
  ];
};

const getDataGridMenu = (): MenuEntry[][] => {
  const editMenu = getEditSectionMenu();

  const menuEntry: MenuEntry[][] = [];
  menuEntry.push(editMenu);
  menuEntry.push([
    {
      label: i18next.t('action.delete', { ns: 'common' }),
      disabled: true,
      type: 'button',
      onClick: () => {
        console.log('Test');
      }
    }
  ]);
  return menuEntry;
};

export const getColumnSettings = (
  commonTypes: CommonTypes | undefined
): TypeColWithNamePropertyPlatform[] => [
  {
    ...getColumnOptions('isUrgent', '', 1),
    cellDOMProps: () => ({
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '4px',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: [
        {
          id: 0,
          label: i18next.t('datagrid.status.urgentShort', { ns: 'orders' })
        }
      ]
    },
    showColumnMenuTool: false,
    textEllipsis: false,
    maxWidth: 75,
    render: ({ data }: { data: Order }) => {
      if (data.isUrgent && data.toManufacture) {
        return (
          <Tooltip>
            <TooltipContent>{i18next.t('datagrid.status.urgent', { ns: 'orders' })}</TooltipContent>
            <span className={styles['datagrid-feature__icon-clock--danger']}>
              <FontAwesomeIcon
                icon={faClockRotateLeft}
                className={styles['datagrid-feature__icon']}
              />
            </span>
          </Tooltip>
        );
      }
    }
  },
  {
    ...getColumnOptions(
      'orderNumber',
      i18next.t('datagrid.columns.orderNumber', { ns: 'orders' }),
      1
    ),
    type: 'number',
    headerAlign: 'start',
    minWidth: 150,
    maxWidth: 250,
    showColumnMenuTool: false
  },
  {
    ...getColumnOptions('dentistName', i18next.t('datagrid.columns.dentist', { ns: 'orders' }), 2),
    showColumnMenuTool: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    render: ({ value }) => {
      if (value) {
        return value.toLowerCase();
      }
    }
  },
  {
    ...getColumnOptions(
      'assigneeName',
      i18next.t('datagrid.columns.assignee', { ns: 'orders' }),
      2
    ),
    defaultFlex: 2,
    showColumnMenuTool: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    render: ({ value }) => {
      if (value) {
        return value.toLowerCase();
      }
    }
  },
  {
    ...getColumnOptions(
      'validationDate',
      i18next.t('datagrid.columns.validationDate', { ns: 'orders' }),
      2
    ),
    showColumnMenuTool: false,
    maxWidth: 115,
    render: ({ value }) => {
      if (value) {
        return typeof value === 'string' ? formatDate(new Date(value)) : '-';
      }
    }
  },
  {
    ...getColumnOptions('currentStep', i18next.t('datagrid.columns.step', { ns: 'orders' }), 2),
    showColumnMenuTool: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        fontWeight: 'bold'
      }
    }),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource:
        commonTypes?.workflowSteps &&
        Object.values(commonTypes?.workflowSteps).map((step) => {
          return {
            id: step,
            label: i18next.t(`order.steps.${step}`, {
              ns: 'orders'
            })
          };
        })
    },
    render: ({ value }) => {
      if (value) {
        return (
          <Tooltip>
            <TooltipContent>{i18next.t(`tooltip.steps.${value}`, { ns: 'orders' })}</TooltipContent>
            <p>
              {i18next.t(`order.steps.${value}`, {
                ns: 'orders'
              }) || '-'}
            </p>
          </Tooltip>
        );
      }
    }
  },
  {
    ...getColumnOptions(
      'families',
      i18next.t('datagrid.columns.family', { ns: 'orders' }),
      2,
      false
    ),
    showColumnMenuTool: false,
    textEllipsis: false,
    minWidth: 200,
    cellDOMProps: () => ({
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '4px',
        alignItems: 'center'
      }
    }),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource:
        commonTypes?.families &&
        Object.values(commonTypes?.families).map((family) => {
          return {
            id: family,
            label: i18next.t(`products.families.${family}`, {
              ns: 'catalog'
            })
          };
        })
    },
    render: ({ value }) => {
      if (commonTypes?.families && value && value?.length > 0) {
        return value.map((family: keyof typeof commonTypes.families) => (
          <Chips
            className={styles['datagrid-feature__chips']}
            key={family.toString().toLowerCase()}
            firstLabel={i18next.t(`products.families.${family}`, {
              ns: 'catalog'
            })}
            color={ColorPropsEnum[`FAMILY_${family.toUpperCase()}` as keyof typeof ColorPropsEnum]}
          />
        ));
      }
    }
  },
  {
    ...getColumnOptions(
      getLocalizedProperty('products'),
      i18next.t('datagrid.columns.productType', { ns: 'orders' }),
      1,
      false
    ),
    showColumnMenuTool: false,
    maxWidth: 100,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 0,
        paddingBottom: 0
      }
    }),
    render: ({ data }: { data: Order }) => {
      if (data?.items?.length) {
        return (
          <>
            {data?.items?.map((item) => (
              <Tooltip key={`tooltip_${item.id}`}>
                <TooltipContent key={`tooltip-content_${item.id}`}>
                  {item?.product[getLocalizedProperty('name') as keyof Product]}
                </TooltipContent>
                <BubblePicto
                  isClickable={false}
                  color={
                    item?.product?.family
                      ? ColorPropsEnum[
                          `FAMILY_${item.product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                        ]
                      : ColorPropsEnum.WHITE
                  }
                  url={item?.product?.imageUrl || ''}
                  key={`bubble_${item.id}`}
                />
              </Tooltip>
            ))}
          </>
        );
      }
    }
  },
  {
    ...getColumnOptions(
      'labName',
      i18next.t('datagrid.columns.laboratory', { ns: 'orders' }),
      1,
      false
    ),
    cellDOMProps: () => ({
      style: {
        textAlign: 'left',
        textTransform: 'capitalize'
      }
    }),
    showColumnMenuTool: false,
    defaultFlex: 2
  },
  {
    ...getColumnOptions('menu', '', 1, false),
    showColumnMenuTool: false,
    maxWidth: 60,
    render: ({ value }) => {
      if (value) {
        return (
          <DropdownMenu
            renderTargetButton={({ active }: { active: boolean }) => (
              <IconButton faIconClass="ellipsis-vertical" isActive={active} radius="full" />
            )}
            data={getDataGridMenu()}
          />
        );
      }
    }
  }
];

export const getFilters = (filterValue: Array<FilterProps> | undefined) => {
  let filters = '';
  filterValue
    ?.filter((filter) => filter.value)
    ?.forEach((filter) => {
      switch (filter.name) {
        case 'families': {
          // multi select filter
          const multiSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.items.product.family=$in:${multiSelectValues.join(',')}`;
          break;
        }
        case 'currentStep': {
          // multi select filter
          const multiSelectValuesArr = filter.value as Array<string>;
          let multiSectValuesString = multiSelectValuesArr.join(',');
          if (multiSelectValuesArr.includes(WorkflowModelingStepEnum.MODELING)) {
            // display all modeling steps when i chose Modeling (to be discussed in user test)
            multiSectValuesString = multiSectValuesString.replace(
              WorkflowModelingStepEnum.MODELING,
              Object.values(WorkflowModelingStepEnum).join(',')
            );
          }
          filters = `${filters}&filter.${filter.name}=$in:${multiSectValuesString}`;
          break;
        }
        default:
          filters = `${filters}&filter.${filter.name}=$ilike:${filter.value}`;
          break;
      }
    });
  return filters;
};

export const getSort = (sortInfo: SortReducer | SortReducer[]) => {
  let urlSort = '';
  if (sortInfo) {
    const sortsArray = Array.isArray(sortInfo) ? sortInfo : [sortInfo];
    sortsArray.forEach(function (sort) {
      urlSort += `${urlSort}&sortBy=${sort?.name}:${
        sort?.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
      }`;
    });
  }
  return urlSort;
};

export const rowClassname = ({ data }: { data: Order }): string | undefined => {
  if (data.isUrgent && data.toManufacture) {
    return styles['datagrid-feature__row--danger'];
  }
  return undefined;
};
