import { DropdownItem } from '../../../../../../../models/form';
import { StringObject } from '../../../../../../../models/common';
import {
  ComponentRuleInProduct,
  ComponentInProductCreationForm,
  Product
} from '../../../../../../../models/product';
import i18next from 'i18next';
import { ColorPropsEnum } from '../../../../../../../enum/color.enum';

export type ActiveComponentsDropdownItems = {
  FRAME: DropdownItem[];
  TOOTH: DropdownItem[];
  GINGIVA: DropdownItem[];
  INFRASTRUCTURE: DropdownItem[];
  PROSTHETIC_STUMP: DropdownItem[];
  IMPLANT_ATTACHMENT: DropdownItem[];
  WAX_ON_HARD_BASE: DropdownItem[];
  IMPLANT_SYSTEM: DropdownItem[];
  GUARD: DropdownItem[];
  PARTIAL_TOOTH: DropdownItem[];
  MODELS: DropdownItem[];
  PINK_GINGIVA: DropdownItem[];
};

export type AddComponentFormProps = {
  onSubmitCallback?: () => void;
  component?: ComponentInProductCreationForm;
  productToEdit?: Product;
  resetComponentTypeToEdit: () => void;
};

export type AddComponentFormData = {
  componentType?: string;
  componentId?: number;
  quantity: AddComponentQuantityEnum;
  hasOnlyOneOptions?: HasOnlyOneOptionsEnum;
  minimumQuantity?: number;
};

export enum AddComponentQuantityEnum {
  HAS_ONLY_ONE = 'hasOnlyOne',
  HAS_SEVERAL = 'hasSeveral'
}

enum HasOnlyOneOptionsEnum {
  IS_OPTIONAL = 'isOptional',
  IS_MANDATORY = 'isMandatory'
}

export const getQuantityRadioOptions = () => {
  return [
    {
      label: i18next.t('products.productForm.components.form.quantity.one.label', {
        ns: 'catalog'
      }),
      value: 'hasOnlyOne'
    },
    {
      label: i18next.t('products.productForm.components.form.quantity.several.label', {
        ns: 'catalog'
      }),
      value: AddComponentQuantityEnum.HAS_SEVERAL
    }
  ];
};

export const getHasOnlyOneRadioOptions = (values: AddComponentFormData) => {
  return [
    {
      label: i18next.t('products.productForm.components.form.quantity.one.optional', {
        ns: 'catalog'
      }),
      value: HasOnlyOneOptionsEnum.IS_OPTIONAL,
      helperText: i18next.t('products.productForm.components.form.quantity.one.optional-helper', {
        ns: 'catalog'
      }),
      isDisabled: values.quantity !== AddComponentQuantityEnum.HAS_ONLY_ONE
    },
    {
      label: i18next.t('products.productForm.components.form.quantity.one.mandatory', {
        ns: 'catalog'
      }),
      value: HasOnlyOneOptionsEnum.IS_MANDATORY,
      helperText: i18next.t('products.productForm.components.form.quantity.one.mandatory-helper', {
        ns: 'catalog'
      }),
      isDisabled: values.quantity !== AddComponentQuantityEnum.HAS_ONLY_ONE
    }
  ];
};

export const computeInitialFormData = (
  minimumQuantity: number,
  component?: ComponentInProductCreationForm
) => {
  const formData: AddComponentFormData = {
    componentType: undefined,
    componentId: undefined,
    quantity: AddComponentQuantityEnum.HAS_ONLY_ONE,
    hasOnlyOneOptions: HasOnlyOneOptionsEnum.IS_OPTIONAL,
    minimumQuantity: minimumQuantity
  };

  // Map existing product rule to form data
  if (component) {
    const isComponentOptional = component.rule.min === 0 && component.rule.max === 1;
    formData.componentType = component.componentType;
    formData.componentId = component.id;
    formData.quantity =
      component.rule.max === 1
        ? AddComponentQuantityEnum.HAS_ONLY_ONE
        : AddComponentQuantityEnum.HAS_SEVERAL;

    if (formData.quantity === AddComponentQuantityEnum.HAS_ONLY_ONE) {
      formData.hasOnlyOneOptions = isComponentOptional
        ? HasOnlyOneOptionsEnum.IS_OPTIONAL
        : HasOnlyOneOptionsEnum.IS_MANDATORY;
    } else {
      formData.hasOnlyOneOptions = undefined;
      formData.minimumQuantity = component.rule.min ?? undefined;
    }
  }
  return formData;
};

export const buildComponentTypeHelper = (
  errors: StringObject,
  componentExistsInProduct: boolean
) => {
  let componentTypeHelper: string | undefined = errors['componentType']
    ? i18next.t(`products.productForm.components.form.componentType.${errors['componentType']}`, {
        ns: 'catalog'
      })
    : undefined;
  let componentTypeHelperVariant = errors['componentType']
    ? ColorPropsEnum.DANGER
    : ColorPropsEnum.DEFAULT;
  if (!componentTypeHelper && componentExistsInProduct) {
    componentTypeHelper = i18next.t('products.productForm.components.form.componentType.warning', {
      ns: 'catalog'
    });
    componentTypeHelperVariant = ColorPropsEnum.DANGER;
  }

  return { text: componentTypeHelper, variant: componentTypeHelperVariant };
};

export const computeComponentsFromFormData = (
  values: AddComponentFormData,
  existingComponents: ComponentInProductCreationForm[],
  activeComponents: ActiveComponentsDropdownItems
) => {
  if (!values?.componentType && !values?.componentId) {
    return existingComponents;
  }
  // In existing components in product, look for a component with the same component type
  // - If none are found, add this component to the list
  // - If we found one, then replace the former component with this one in the list
  //   Since a product can only have one component per component type

  const computeComponentRule = () => {
    const componentRule: ComponentRuleInProduct = {
      min: 0,
      max: 0
    };
    if (values.quantity === AddComponentQuantityEnum.HAS_ONLY_ONE) {
      componentRule.max = 1;
      componentRule.min = values.hasOnlyOneOptions === HasOnlyOneOptionsEnum.IS_OPTIONAL ? 0 : 1;
    } else if (values?.minimumQuantity) {
      componentRule.min = +values.minimumQuantity;
      componentRule.max = 16;
    }
    return componentRule;
  };

  const componentDetails = Object.values(
    activeComponents[values.componentType as keyof ActiveComponentsDropdownItems]
  ).find((activeComponent) => activeComponent.value === values.componentId);

  if (!componentDetails) {
    console.error(`Component with id ${values.componentId} not found in active components`);
  }

  const newComponent: ComponentInProductCreationForm = {
    id: values.componentId as number,
    componentType: values.componentType as string,
    label: (componentDetails as DropdownItem).label,
    rule: computeComponentRule()
  };

  const existingComponentIndex = existingComponents?.findIndex(
    (component) => component.componentType === values.componentType
  );
  // Create a new components array
  const updatedComponents =
    existingComponentIndex === -1
      ? [...existingComponents, newComponent] // Add new component
      : existingComponents.map((component, index) =>
          index === existingComponentIndex ? newComponent : component
        ); // Update existing component

  return updatedComponents;
};
