import { createSelector } from '@reduxjs/toolkit';
import { capitalize } from '../../utils/utils';
import { circleCadVersionApi } from '../../services/circle-cad-version.services';

const selectCircleCadVersionsResult = circleCadVersionApi.endpoints.getCircleCadVersions.select();

export const activeCircleCadVersionsDroprownSelector = createSelector(
  selectCircleCadVersionsResult,
  (circleCadVersions) =>
    circleCadVersions.data?.map((circleCadVersion) => ({
      label: `${capitalize(circleCadVersion.label)} (${circleCadVersion.version})`,
      value: circleCadVersion.version
    }))
);
