import { ComponentsReducer } from '../../models/component';

interface ReducerState {
  componentState: ComponentsReducer;
}
export const componentSelector = (state: ReducerState) => state.componentState?.component;

export const componentDetailSelector = (state: ReducerState) =>
  state.componentState?.componentDetails;

export const isComponentLoadingSelector = (state: ReducerState) =>
  state.componentState.isComponentLoading;
