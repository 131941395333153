import axios, { AxiosResponse } from 'axios';
import moment from 'moment/moment';

export const exportManufacturedOrders = (
  startManufacturingStartRange: Date,
  startManufacturingEndRange: Date
): Promise<AxiosResponse<object>> => {
  return axios.get(
    `/manufacturing/orders/export/manufacturing-started?filter.startManufacturingDate=$btw:${moment(
      startManufacturingStartRange
    ).format('YYYY-MM-DDT00:00:00.000Z')},${moment(startManufacturingEndRange).format(
      'YYYY-MM-DDT23:59:59.000Z'
    )}`,
    { responseType: 'arraybuffer' }
  );
};
