import { UserInfo, UsersReducer } from '../../models/user';
import { AdminRole } from '../../enum/user.ts';
import { createSelector } from '@reduxjs/toolkit';
import { usersApi } from '../../services/users-rtkq.services.tsx';

interface ReducerState {
  userState: UsersReducer;
}

export const isUserLoadingSelector = (state: ReducerState) => state.userState.isUserLoading;

export const userSelector = (state: ReducerState) => state.userState.user;

export const isLoadingCreationUserSelector = (state: ReducerState) =>
  state.userState.isLoadingCreationUser;

export const isLoadingPatchUserSelector = (state: ReducerState) =>
  state.userState.isLoadingPatchUser;

// Connected user
const selectConnectedUserResult = usersApi.endpoints.getConnectedUser.select();

// Automatically fetch connected user & use the response to compute whether they're admin or not
export const isConnectedUserAdminSelector = createSelector(
  selectConnectedUserResult,
  (selectConnectedUserResult) => {
    const connectedUser: UserInfo | undefined = selectConnectedUserResult.data;
    return connectedUser?.role === AdminRole.ADMIN;
  }
);
