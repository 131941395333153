import styles from './create-component-page.module.scss';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BreadcrumbItem, BreadcrumbsBar, Wizard } from '@anatoscope/circlestorybook';
import { useAppDispatch } from '../../../../../store/hooks';
import IdentificationForm from './create-component-forms/IdentificationForm';
import OptionsForm from './create-component-forms/OptionsForm';
import MaterialShadeForm from './create-component-forms/MaterialShadeForm';
import ShapeStructureForm from './create-component-forms/ShapeStructureForm';
import { componentsActions } from '../../../../../store/components/components.reducers';
import SummaryForm from './create-component-forms/SummaryForm';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import { ToastType } from '../../../../../enum/feedback';

const CreateComponentPage = () => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedStep, setSelectedStep] = useState<number>(0);
  const steps = [
    { id: 0, title: t('components.componentForm.wizard.identification') },
    { id: 1, title: t('components.componentForm.wizard.material-shade') },
    { id: 2, title: t('components.componentForm.wizard.shape-structure') },
    { id: 3, title: t('components.componentForm.wizard.options') },
    { id: 4, title: t('components.componentForm.wizard.summary') }
  ];

  const navigateNextStep = () => {
    setSelectedStep(selectedStep + 1);
  };

  const navigateBackStep = () => {
    setSelectedStep(selectedStep - 1);
  };

  const handleSubmit = () => {
    navigate('/catalog/components');
    dispatch(
      feedbackActions.setToast({
        message: t('components.componentForm.toast.created'),
        type: ToastType.SUCCESS
      })
    );
    dispatch(componentsActions.resetComponent());
  };

  return (
    <div className={styles['create-component-page']}>
      <BreadcrumbsBar type={'navigation'}>
        <BreadcrumbItem
          text={t('components.componentForm.breadcrumb.components')}
          link="/catalog/components"></BreadcrumbItem>
        <BreadcrumbItem
          text={t('components.componentForm.breadcrumb.create')}
          link="/catalog/components/create"></BreadcrumbItem>
      </BreadcrumbsBar>

      <div className={styles['create-component-page__main']}>
        <Wizard
          options={steps}
          selectedIndex={selectedStep}
          displayedInRow={false}
          className={styles['create-component-page__wizard']}
        />
        {selectedStep === 0 && <IdentificationForm nextCallback={navigateNextStep} />}
        {selectedStep === 1 && (
          <MaterialShadeForm nextCallback={navigateNextStep} previousCallback={navigateBackStep} />
        )}
        {selectedStep === 2 && (
          <ShapeStructureForm nextCallback={navigateNextStep} previousCallback={navigateBackStep} />
        )}
        {selectedStep === 3 && (
          <OptionsForm nextCallback={navigateNextStep} previousCallback={navigateBackStep} />
        )}
        {selectedStep === 4 && (
          <SummaryForm submitCallback={handleSubmit} previousCallback={navigateBackStep} />
        )}
      </div>
    </div>
  );
};

export default CreateComponentPage;
