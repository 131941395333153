import { ProductsReducer } from '../../models/product';
import { getCircleOneCompatibility } from '../../utils/utils';

interface ReducerState {
  productState: ProductsReducer;
}

export const productSelector = (state: ReducerState) => state.productState.product;

export const circleOneCompatibilitySelector = (state: ReducerState) =>
  getCircleOneCompatibility(state.productState?.product);

export const circleOneCompatibilityDetailsSelector = (state: ReducerState) =>
  getCircleOneCompatibility(state.productState?.productDetails);
